<div *ngIf="loading">
    <mat-spinner class="mt-3" diameter="20" />
</div>

<div *ngIf="!loading" class="row scrollContainer tab col-md-10" style="margin-bottom: 20px;">
    <div class="col-md-9 mb-9 scrollColumn tab">
        <app-table [dataSource]="dataSource" [displayedColumns]="displayedColumns" [showActions]="false"
            uniqueKey="features" *ngIf="!loading" (rowClick)="rowClick($event)"></app-table>
    </div>
    <div class="col-md-3">
        <app-activity [tab]="true" [id]="instance.id" table="instance" />
    </div>
</div>

<ng-template #updateDialog>
    <div class="update-dialog">
        <h2 matDialogTitle>{{selectedFeature.name}}</h2>
        <p>{{selectedFeature.description}}</p>

        <div *ngFor="let site of sites" >
            <mat-checkbox [indeterminate]="allSitesCheckboxValue !== undefined" color="primary" name="site.name"
                (click)="onToggleIndividualSite()" [(ngModel)]="siteEnablement[site.weavixSiteId]">
                {{site.name}}
            </mat-checkbox>
        </div>
        <br>
        <div>
            <mat-checkbox [indeterminate]="allSitesCheckboxValue === undefined" color="primary" name="all"
                (change)="toggleAllSites()" [(ngModel)]="allSitesCheckboxValue">
                All Sites
            </mat-checkbox>
            <p class="text-muted">Enabling or Disabling "All Sites" will use that setting for all current and future sites
                that may be added.</p>
        </div>
        <hr>
        <div class="d-flex justify-content-between">
            <button mat-raised-button class="demo-button" color="primary" (click)="save()">
                Save
            </button>
            <button mat-raised-button class="demo-button" color="secondary" (click)="useDefaultFeatureValue()">
                Use Feature Default ({{selectedFeature.defaultValue ? 'Enabled' : 'Disabled'}})
            </button>
        </div>
    </div>
</ng-template>