import { RouterModule, Routes } from '@angular/router';

import { AssetComponent } from './inventory/asset/asset.component';
import { AssetTypeComponent } from './inventory/assetType/assetType.component';
import { AssetTypesComponent } from './inventory/assetTypes/assetTypes.component';
import { AssetsComponent } from './inventory/assets/assets.component';
import { BundleComponent } from './inventory/bundle/bundle.component';
import { BundleTypeComponent } from './inventory/bundleType/bundleType.component';
import { BundleTypesComponent } from './inventory/bundleTypes/bundleTypes.component';
import { BundlesComponent } from './inventory/bundles/bundles.component';
import { CompaniesComponent } from './companies/companies/companies.component';
import { CompanyComponent } from './companies/company/company.component';
import { ConsumableComponent } from './inventory/consumable/consumable.component';
import { ConsumablesComponent } from './inventory/consumables/consumables.component';
import { HomeComponent } from './home/home.component';
import { InstanceComponent } from './companies/instance/instance.component';
import { NgModule } from '@angular/core';
import { OrderComponent } from './companies/order/order.component';
import { PeopleComponent } from './companies/people/people.component';
import { PersonComponent } from './companies/person/person.component';
import { SiteComponent } from './companies/site/site.component';
import { VersionsComponent } from './administration/versions/versions.component';
import { WarehousesComponent } from './inventory/warehouses/warehouses.component';
import { ScannerComponent } from './inventory/scanner/scanner.component';
import { OrphanedInstancesComponent } from './administration/orphanedInstances/orphanedInstances.component';
import { FeaturesComponent } from './administration/features/features.component';

const routes: Routes = [
  { path: '*', redirectTo: '', pathMatch: 'full' },
  { path: '', component: HomeComponent },

  //Company Routes
  { path: 'companies/companies', component: CompaniesComponent },
  { path: 'companies/companies/:id', component: CompanyComponent },
  { path: 'companies/people', component: PeopleComponent },
  { path: 'companies/people/:id', component: PersonComponent },
  { path: 'companies/order/:id', component: OrderComponent },
  { path: 'companies/sites/:id', component: SiteComponent },
  { path: 'companies/instances/:id', component: InstanceComponent },

  //Inventory Routes
  { path: 'inventory/assetTypes', component: AssetTypesComponent },
  { path: 'inventory/assetTypes/:id', component: AssetTypeComponent },
  { path: 'inventory/assetTypes/assets/:id', component: AssetsComponent },
  { path: 'inventory/assets/:id', component: AssetComponent },
  { path: 'inventory/warehouses', component: WarehousesComponent },
  { path: 'inventory/consumables', component: ConsumablesComponent },
  { path: 'inventory/consumables/:id', component: ConsumableComponent },
  { path: 'inventory/bundleTypes/bundles/:id', component: BundlesComponent },
  { path: 'inventory/bundles/:id', component: BundleComponent },
  { path: 'inventory/bundleTypes', component: BundleTypesComponent },
  { path: 'inventory/bundleTypes/:id', component: BundleTypeComponent },
  { path: 'inventory/scanner', component: ScannerComponent },

  //Admin routes
  { path: 'admin/versions', component: VersionsComponent },
  { path: 'admin/orphanedInstances', component: OrphanedInstancesComponent },
  { path: 'admin/features', component: FeaturesComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
