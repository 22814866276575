export enum PermissionType {
    None = 0,
    Read = 1,
    Edit = 2,
}

export class Permission {
    Assets: PermissionType;
    AssetTypes: PermissionType;
    Bundles: PermissionType;
    BundleTypes: PermissionType;
    Companies: PermissionType;
    CompanyInstances: PermissionType;
    Consumables: PermissionType;
    ConsumableStock: PermissionType;
    ControlCenter: PermissionType;
    Deals: PermissionType;
    Fulfillments: PermissionType;
    Instances: PermissionType;
    Orders: PermissionType;
    Partners: PermissionType;
    People: PermissionType;
    Products: PermissionType;
    PullLogs: PermissionType;
    SupportAccess: PermissionType;
    Versions: PermissionType;
    Walts: PermissionType;
    TwoWaltDemo: PermissionType;
    Warehouses: PermissionType;
    Wilmas: PermissionType;
    FeatureFlags: PermissionType;
}

export interface PermissionChanges {
    [key: string]: {
        old: number | boolean;
        new: number | boolean;
    };
}
