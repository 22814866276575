import { Component, OnInit, ViewChild } from '@angular/core';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from 'app/services/http.service';
import { autoFitBoundaries } from 'app/google-map-utils';
import { AssetStatuses } from 'app/models/control-center/asset.model';
import { Utils } from 'app/utils';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-assets',
    templateUrl: './assets.component.html',
    styleUrls: ['./assets.component.scss'],
})
export class AssetsComponent implements OnInit {

    loading = true;
    dataSource: any[] = [];

    @ViewChild(GoogleMap) map?: GoogleMap;
    @ViewChild(MapInfoWindow) infoWindow?: MapInfoWindow;

    displayedColumns: any = [
        { key: 'status', label: 'Status', type: 'badge' },
        { key: 'statusDetail', label: 'Status Details', type: 'string' },
        { key: 'company', label: 'Company', type: 'string' },
        { key: 'name', label: 'Name', type: 'string' },
        { key: 'assetModel', label: 'Model', type: 'string' },
        { key: 'serialNumber', label: 'Serial Number', type: 'string' },
        { key: 'version', label: 'Version', type: 'string' },
        { key: 'locationUpdated', label: 'Last Seen', type: 'date' },
        { key: 'menu', label: 'Actions', type: 'menu' },
    ];
    tableFilter: any = [];

    permissions;
    assetTypeId = '';
    assetType = '';

    warehouses = [];
    models = [];
    people = [];
    sites = [];
    warehouseId: number;
    expectedDeliveryDate: string;
    modelId: number;
    file: File;
    waltAssetType = environment.waltAssetType.toString();
    tableActions = [{ name: 'Update Status', key: 'updateStatus', requireItemSelection: true }];
    bulkUpdateStatuses: {
        status?: AssetStatuses;
        assetIds?: number[];
        warehouseId?: number;
        warehouseLocation?: string;
        personId?: number;
        siteId?: number;
    } = {};
    assetStatuses = AssetStatuses;

    formatDate = Utils.formatDate;
    importResult = '';

    @ViewChild('importDialog') importDialog: any;
    @ViewChild('importResultDialog') importResultDialog: any;
    @ViewChild('updateStatusDialog') updateStatusDialog: any;

    constructor(private _httpService: HttpService, private _router: Router, private route: ActivatedRoute, private dialog: MatDialog) { }

    async ngOnInit(): Promise<void> {
        this.permissions = await this._httpService.get('people/authenticated/permissions');
        this.load();
    }

    async load() {
        const loadPeople = async () => {
            // We only use people for bulk updates, no need to await this to display the page
            this.people = await this._httpService.get('people');
        };

        this.loading = true;
        this.assetTypeId = this.route.snapshot.params['id'];
        loadPeople();

        await Promise.all([
            (async () => this.assetType = (await this._httpService.get('inventory/assetTypes/' + this.assetTypeId)).name)(),
            (async () => this.dataSource = await this._httpService.get('inventory/assetTypes/assets/' + this.assetTypeId))(),
            (async () => this.permissions = await this._httpService.get('people/authenticated/permissions'))(),
            (async () => this.warehouses = await this._httpService.get('inventory/warehouses'))(),
            (async () => this.models = await this._httpService.get(`inventory/assetTypes/${this.assetTypeId}/assetModels`))(),
            (async () => this.sites = await this._httpService.get('companies/sites'))(),
        ]);

        for (let i = 0; i < this.dataSource.length; i++) {

            this.dataSource[i].name = this.dataSource[i].alais ? this.dataSource[i].alais : this.dataSource[i].serialNumber;
            this.dataSource[i].expectedDeliveryDate = new Date(this.dataSource[i].expectedDeliveryDate).toLocaleDateString();

            switch (this.dataSource[i].status) {
                case 'Maintenance':
                    this.dataSource[i].color = '#FFA500';
                    this.dataSource[i].statusDetail = this.dataSource[i].personName;
                    break;
                case 'Checked Out':
                    this.dataSource[i].color = '#b53737';
                    this.dataSource[i].statusDetail = this.dataSource[i].personName;
                    break;
                case 'Available':
                    this.dataSource[i].color = '#62bd69';
                    this.dataSource[i].statusDetail = this.dataSource[i].warehouse + ' - ' + this.dataSource[i].warehouseLocation;
                    break;
                case 'Provisioning':
                    this.dataSource[i].color = '#aaaaaa';
                    this.dataSource[i].statusDetail = this.dataSource[i].warehouse;
                    break;
                case 'Incoming Forecast':
                    this.dataSource[i].color = '#aaaaaa';
                    this.dataSource[i].statusDetail = this.dataSource[i].expectedDeliveryDate;
                    break;
                default:
                    this.dataSource[i].color = '#aaaaaa';
            }

        }

        this.tableFilter = [
            {
                show: true, type: 'select', name: 'Status', key: 'status', options: Object.keys(AssetStatuses).map(k => {
                    return { value: k, label: AssetStatuses[k] };
                }),
            },
            { show: true, type: 'select', name: 'Status Details', key: 'statusDetail', options: this.getFilterOptions('statusDetail') },
            { show: true, type: 'select', name: 'Company', key: 'company', options: this.getFilterOptions('company') },
            { show: true, type: 'input', name: 'Name', key: 'name', options: {} },
            { show: true, type: 'select', name: 'Model', key: 'assetModel', options: this.getFilterOptions('assetModel') },
            { show: true, type: 'input', name: 'Serial Number', key: 'serialNumber', options: {} },
            { show: true, type: 'input', name: 'Version', key: 'version', options: {} },
            { show: true, type: 'dateRange', name: 'Last Seen', key: 'locationUpdated', options: {} },
        ];

        this.loading = false;
    }

    getFilterOptions(columnName: string): any {
        const result = Array.from(new Set(this.dataSource.map((item: any) => item[columnName]))).sort();
        return Object.keys(result).map(k => {
            return { value: (result[k] == null ? '' : result[k].replaceAll(' ', '')), label: result[k] };
        });
    }

    add() {
        this._router.navigateByUrl('/inventory/assets/0?assetTypeId=' + this.assetTypeId);
    }

    rowClick(id: any) {
        this._router.navigateByUrl('/inventory/assets/' + id);
    }

    async delete(ids: []) {
        this.loading = true;
        const promises = [];

        for (let i = 0; i < ids.length; i++) {
            promises.push(this._httpService.delete(`inventory/assets/${ids[i]}`));
        }
        await Promise.all(promises);
        await this.load();
    }

    showResultDialog() {
        this.dialog.open(this.importResultDialog);
    }

    showImport() {
        const dialogRef = this.dialog.open(this.importDialog);
        dialogRef.afterClosed().subscribe(async result => {
            if (result !== undefined) {
                if (result === 'import') {
                    try {
                        const formData = new FormData();

                        formData.append('file', this.file);
                        formData.append('assetModelId', this.modelId.toString());
                        formData.append('assetTypeId', this.assetTypeId.toString());
                        formData.append('expectedDeliveryDate', this.expectedDeliveryDate.toString());
                        formData.append('warehouseId', this.warehouseId.toString());

                        const result = await this._httpService.postForm('inventory/assets/import', formData);
                        if (result.status === 'error') {
                            this.importResult = `An error occurred while importing assets: ${result.error}`;
                            return;
                        }
                        this.importResult = 'Assets imported successfully.';
                    } catch (error) {
                        console.error(error);
                        this.importResult = `An error occurred while importing assets`;
                    } finally {
                        this.showResultDialog();
                        this.file = null;
                        this.modelId = null;
                        this.assetTypeId = null;
                        this.expectedDeliveryDate = null;
                        this.warehouseId = null;
                        await this.load();
                    }
                }
            }
        });
    }

    async onFileSelected(event) {
        this.file = event.target.files[0];
    }

    toNum(value): number {
        return Number(value);
    }

    handleTableAction(action: { key: string, ids: number[] }): void {
        this.bulkUpdateStatuses = {};
        this.bulkUpdateStatuses.assetIds = action.ids;
        const dialogRef = this.dialog.open(this.updateStatusDialog);
        dialogRef.afterClosed().subscribe(async result => {
            if (result !== undefined) {
                if (result === 'update') {
                    this.loading = true;
                    await this._httpService.put('inventory/assets/statuses', this.bulkUpdateStatuses);
                    await this.load();
                    this.loading = false;
                }
            }
        });
    }

    openedAsset?: any;

    markerClick(asset: any, marker: MapMarker) {
        if (this.infoWindow) {
            this.openedAsset = asset;
            this.infoWindow.open(marker);
        }
    }

    private didFitBounds = false;

    fitBounds() {
        if (this.didFitBounds) {
            return;
        }
        const positions = this.dataSource
            ?.filter(asset => asset.longitude && asset.latitude)
            .map(asset => ({ lat: +asset.longitude, lng: +asset.latitude }));
        this.didFitBounds = autoFitBoundaries(this.map, positions);
    }

    exportRowOverride(row: any) {
        if (row['eIdOne']) row['eIdOne'] = `'${row['eIdOne']}'`;
        if (row['eIdTwo']) row['eIdTwo'] = `'${row['eIdTwo']}'`;
        if (row['imeiOne']) row['imeiOne'] = `'${row['imeiOne']}'`;
        if (row['imeiTwo']) row['imeiTwo'] = `'${row['imeiTwo']}'`;
    }

}
