import { FrontendEnvironment } from '@weavix/domain/src/frontend-environment/frontend-environment';

const frontendEnvironment = new FrontendEnvironment(window.location.hostname);
const domain = frontendEnvironment.domain;

export const environment = {
  production: frontendEnvironment.isProduction(),
  url: `${frontendEnvironment.getSiblingUrl('api-controlcenter')}/`,
  waltAssetType: (domain === 'weavixdev.com' || domain === 'localhost') ? 4 : 48,
  releaseStage: frontendEnvironment.getReleaseStage(),
  version: '5.22.23', //Just like console, this is set using set-ng-environement-version.sh
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
