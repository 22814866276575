<app-authentication *ngIf="!loading && !authenticated" (authenticated)="authenticate()"></app-authentication>

<div *ngIf="!loading && authenticated">
    <header class="navbar navbar-dark sticky-top bg-dark flex-md-nowrap p-0 shadow">
        <div class="navbar-brand col-md-3 col-lg-2 me-0 px-3" href="#"><img src="../assets/logo_light.png" height="30">
        </div>
        <button class="navbar-toggler position-absolute d-md-none collapsed" type="button" data-bs-toggle="collapse"
            data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
            aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-nav">
            <div class="nav-item text-nowrap">
                <a class="nav-link px-3" style="cursor: pointer;" (click)="logOut()">{{authenticatedPerson}} -
                    {{authenticatedCompany}} - Sign out</a>
            </div>
        </div>
    </header>

    <app-session-expiry-banner />

    <div class="container-fluid">
        <div class="row">
            <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar menuContainer">
                <div class="position-sticky pt-3 menuColumn">

                    <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
                        *ngIf="permissions.Companies > permissionType.None || permissions.Deals > permissionType.None || permissions.Partners > permissionType.None || permissions.People > permissionType.None">
                        <span>Customers</span>

                    </h6>
                    <ul class="nav flex-column mb-2" *ngIf="permissions.Companies > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/companies/companies']">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192z" />
                                </svg>
                                Companies
                            </a>
                        </li>
                    </ul>

                    <ul class="nav flex-column mb-2" *ngIf="permissions.People > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/companies/people']">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 512 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M256 288c79.53 0 144-64.47 144-144s-64.47-144-144-144c-79.52 0-144 64.47-144 144S176.5 288 256 288zM351.1 320H160c-88.36 0-160 71.63-160 160c0 17.67 14.33 32 31.1 32H480c17.67 0 31.1-14.33 31.1-32C512 391.6 440.4 320 351.1 320z" />
                                </svg>
                                People
                            </a>
                        </li>
                    </ul>

                    <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
                        *ngIf="permissions.Assets > permissionType.None || permissions.Bundles > permissionType.None || permissions.Consumables > permissionType.None || permissions.Warehouses > permissionType.None">
                        <span>Inventory</span>

                    </h6>
                    <ul class="nav flex-column mb-2" *ngIf="permissions.Assets > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/inventory/assetTypes']">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M240 320h320c26.4 0 48-21.6 48-48v-192C608 53.6 586.4 32 560 32H448v128l-48-32L352 160V32H240C213.6 32 192 53.6 192 80v192C192 298.4 213.6 320 240 320zM608 384H128V64c0-35.2-28.8-64-64-64H31.1C14.4 0 0 14.4 0 32S14.4 64 31.1 64H48C56.84 64 64 71.16 64 80v335.1c0 17.6 14.4 32 32 32l66.92-.0009C161.1 453 160 458.4 160 464C160 490.5 181.5 512 208 512S256 490.5 256 464c0-5.641-1.13-10.97-2.917-16h197.9c-1.787 5.027-2.928 10.36-2.928 16C448 490.5 469.5 512 496 512c26.51 0 48.01-21.49 48.01-47.1c0-5.641-1.12-10.97-2.907-16l66.88 .0009C625.6 448 640 433.6 640 415.1C640 398.4 625.6 384 608 384z" />
                                </svg>
                                Assets
                            </a>
                        </li>
                    </ul>
                    <ul class="nav flex-column mb-2" *ngIf="permissions.Bundles > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/inventory/bundleTypes']">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--! Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M160 48C160 21.49 181.5 0 208 0H256V80C256 88.84 263.2 96 272 96H304C312.8 96 320 88.84 320 80V0H368C394.5 0 416 21.49 416 48V176C416 202.5 394.5 224 368 224H208C181.5 224 160 202.5 160 176V48zM96 288V368C96 376.8 103.2 384 112 384H144C152.8 384 160 376.8 160 368V288H208C234.5 288 256 309.5 256 336V464C256 490.5 234.5 512 208 512H48C21.49 512 0 490.5 0 464V336C0 309.5 21.49 288 48 288H96zM416 288V368C416 376.8 423.2 384 432 384H464C472.8 384 480 376.8 480 368V288H528C554.5 288 576 309.5 576 336V464C576 490.5 554.5 512 528 512H368C341.5 512 320 490.5 320 464V336C320 309.5 341.5 288 368 288H416z" />
                                </svg>
                                Bundles
                            </a>
                        </li>
                    </ul>
                    <ul class="nav flex-column mb-2" *ngIf="permissions.Consumables > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/inventory/consumables']">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M384 172.4c0 71.02-38.7 132.8-96 166v125.5C288 490.5 266.5 512 240 512h-96C117.5 512 96 490.5 96 463.1v-125.5c-57.3-33.22-96-94.99-96-166c0-74.41 42.4-138.8 104.3-170.6C115.1-3.711 128 3.992 128 16.1v143.9l64 64l64-64V16.1c0-12.11 12.93-19.81 23.7-14.27C341.6 33.67 384 98.04 384 172.4z" />
                                </svg>
                                Consumables
                            </a>
                        </li>
                    </ul>
                    <ul class="nav flex-column mb-2" *ngIf="permissions.Fulfillments > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/inventory/scanner']">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M112 32C50.1 32 0 82.1 0 144c0 50.7 33.8 93.6 80 107.4L8.1 374.7c-13.3 22.8-5.7 52.2 17.1 65.6l53.4 31.4c22.8 13.4 52.3 5.8 65.7-17L261.2 256H304c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48H112zm328 0c-13.3 0-24 10.7-24 24V72c0 13.3 10.7 24 24 24H552c13.3 0 24-10.7 24-24V56c0-13.3-10.7-24-24-24H440zM416 216v16c0 13.3 10.7 24 24 24H552c13.3 0 24-10.7 24-24V216c0-13.3-10.7-24-24-24H440c-13.3 0-24 10.7-24 24zm24 200c-13.3 0-24 10.7-24 24v16c0 13.3 10.7 24 24 24H552c13.3 0 24-10.7 24-24V440c0-13.3-10.7-24-24-24H440zM416 144c0 8.8 7.2 16 16 16H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H432c-8.8 0-16 7.2-16 16zm16 144c-8.8 0-16 7.2-16 16s7.2 16 16 16H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H432zm-16 80c0 8.8 7.2 16 16 16H560c8.8 0 16-7.2 16-16s-7.2-16-16-16H432c-8.8 0-16 7.2-16 16z" />
                                </svg>
                                Scanner
                            </a>
                        </li>
                    </ul>
                    <ul class="nav flex-column mb-2" *ngIf="permissions.Warehouses > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/inventory/warehouses']">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M0 488V171.3C0 145.2 15.93 121.6 40.23 111.9L308.1 4.753C315.7 1.702 324.3 1.702 331.9 4.753L599.8 111.9C624.1 121.6 640 145.2 640 171.3V488C640 501.3 629.3 512 616 512H568C554.7 512 544 501.3 544 488V223.1C544 206.3 529.7 191.1 512 191.1H128C110.3 191.1 96 206.3 96 223.1V488C96 501.3 85.25 512 72 512H24C10.75 512 0 501.3 0 488zM488 384C501.3 384 512 394.7 512 408V488C512 501.3 501.3 512 488 512H376C362.7 512 352 501.3 352 488V408C352 394.7 362.7 384 376 384H488zM128 247.1C128 234.7 138.7 223.1 152 223.1H296C309.3 223.1 320 234.7 320 247.1V328C320 341.3 309.3 352 296 352H152C138.7 352 128 341.3 128 328V247.1zM296 384C309.3 384 320 394.7 320 408V488C320 501.3 309.3 512 296 512H152C138.7 512 128 501.3 128 488V408C128 394.7 138.7 384 152 384H296z" />
                                </svg>
                                Warehouses
                            </a>
                        </li>
                    </ul>

                    <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted"
                        *ngIf="permissions.Wilmas > permissionType.None || permissions.Instances > permissionType.None || permissions.Versions > permissionType.None">
                        <span>Administration</span>

                    </h6>
                    <ul class="nav flex-column mb-2" *ngIf="permissions.Instances > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/admin/orphanedInstances']" style="cursor: pointer;">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--! Font Awesome Pro 6.2.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M38.8 5.1C28.4-3.1 13.3-1.2 5.1 9.2S-1.2 34.7 9.2 42.9l592 464c10.4 8.2 25.5 6.3 33.7-4.1s6.3-25.5-4.1-33.7L489.3 358.2l90.5-90.5c56.5-56.5 56.5-148 0-204.5c-50-50-128.8-56.5-186.3-15.4l-1.6 1.1c-14.4 10.3-17.7 30.3-7.4 44.6s30.3 17.7 44.6 7.4l1.6-1.1c32.1-22.9 76-19.3 103.8 8.6c31.5 31.5 31.5 82.5 0 114l-96 96-31.9-25C430.9 239.6 420.1 175.1 377 132c-52.2-52.3-134.5-56.2-191.3-11.7L38.8 5.1zM239 162c30.1-14.9 67.7-9.9 92.8 15.3c20 20 27.5 48.3 21.7 74.5L239 162zM406.6 416.4L220.9 270c-2.1 39.8 12.2 80.1 42.2 110c38.9 38.9 94.4 51 143.6 36.3zm-290-228.5L60.2 244.3c-56.5 56.5-56.5 148 0 204.5c50 50 128.8 56.5 186.3 15.4l1.6-1.1c14.4-10.3 17.7-30.3 7.4-44.6s-30.3-17.7-44.6-7.4l-1.6 1.1c-32.1 22.9-76 19.3-103.8-8.6C74 372 74 321 105.5 289.5l61.8-61.8-50.6-39.9z" />
                                </svg>
                                Orphaned Instances
                            </a>
                        </li>
                    </ul>
                    <ul class="nav flex-column mb-2" *ngIf="permissions.Versions > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/admin/versions']" style="cursor: pointer;">

                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 640 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                                    <path
                                        d="M160 80C160 112.8 140.3 140.1 112 153.3V241.1C130.8 230.2 152.7 224 176 224H272C307.3 224 336 195.3 336 160V153.3C307.7 140.1 288 112.8 288 80C288 35.82 323.8 0 368 0C412.2 0 448 35.82 448 80C448 112.8 428.3 140.1 400 153.3V160C400 230.7 342.7 288 272 288H176C140.7 288 112 316.7 112 352V358.7C140.3 371 160 399.2 160 432C160 476.2 124.2 512 80 512C35.82 512 0 476.2 0 432C0 399.2 19.75 371 48 358.7V153.3C19.75 140.1 0 112.8 0 80C0 35.82 35.82 0 80 0C124.2 0 160 35.82 160 80V80zM80 104C93.25 104 104 93.25 104 80C104 66.75 93.25 56 80 56C66.75 56 56 66.75 56 80C56 93.25 66.75 104 80 104zM368 56C354.7 56 344 66.75 344 80C344 93.25 354.7 104 368 104C381.3 104 392 93.25 392 80C392 66.75 381.3 56 368 56zM80 456C93.25 456 104 445.3 104 432C104 418.7 93.25 408 80 408C66.75 408 56 418.7 56 432C56 445.3 66.75 456 80 456z" />
                                </svg>
                                Versions
                            </a>
                        </li>
                    </ul>
                    <ul class="nav flex-column mb-2" *ngIf="permissions.FeatureFlags > permissionType.None">
                        <li class="nav-item">
                            <a class="nav-link" [routerLink]="['/admin/features']" style="cursor: pointer;">

                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"
                                    stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" class="feather feather-file-text" aria-hidden="true">
                                    <!--!Font Awesome Free 6.7.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2025 Fonticons, Inc.-->
                                    <path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>
                                Features
                            </a>
                        </li>
                    </ul>

                </div>
            </nav>

            <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">

                <router-outlet></router-outlet>

            </main>
        </div>
    </div>
</div>