import { WaltAccessoryType } from '@weavix/models/src/facility/facility';

export class Language {
    id: number;
    name: string;
    abbreviation: string;
}

export interface HeadsetAccessory {
    id: number;
    headsetType: WaltAccessoryType;
    name: string;
}

export class Site {
    id: number;
    name: string;
    companyId: number;
    contactPersonId: number;
    instanceId: number;
    languageId: number;
    shippingAddress?: string;
    address?: string;
    location?: any[];
    overrideWifiRoaming?: number;
    waltAccessories?: WaltAccessoryType[];
    weavixSiteId?: string;
}
