import { AfterContentInit, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { SelectionModel } from '@angular/cdk/collections';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { AssetFilter } from 'app/models/control-center/asset.model';
import { PermissionType } from 'app/models/control-center/permission.model';
import { Utils } from 'app/utils';

@Component({
  selector: 'app-table-multi-filter',
  templateUrl: './tableMultiFilter.component.html',
  styleUrls: ['./tableMultiFilter.component.scss'],
})
export class TableMultiFilterComponent implements OnInit, AfterViewInit, AfterContentInit {

  constructor(private dialog: MatDialog) { }

  @Output() createClick = new EventEmitter<any>();
  @Output() rowClick = new EventEmitter<any>();
  @Output() deleteClick = new EventEmitter<any>();
  @Output() editClick = new EventEmitter<any>();
  @Output() importClick = new EventEmitter<any>();
  @Output() actionClick = new EventEmitter<any>();

  @Input() displayedColumns: any = [];
  @Input() dataSource: any = [];
  @Input() route = '';
  @Input() checkBoxes = false;
  @Input() search = true;
  @Input() uniqueKey = 'default';
  @Input() clickToEdit = true;
  @Input() showAdd = true;
  @Input() showDelete = true;
  @Input() paginate = true;
  @Input() permission = PermissionType.None;
  @Input() showImport = false;
  @Input() filters: any = [];
  @Input() additionalActions: Array<{ name: string, key: string, requireItemSelection: boolean }>;

  /** Provide a function here to parse row data before sending it to the csv builder.  Useful when you need to wrap or tweak
   * values before exporting.
   */
  @Input() exportRowOverride?: (row: any) => void;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('deleteDialog') deleteDialog: any;
  @ViewChild('deleteManyDialog') deleteManyDialog: any;

  selection = new SelectionModel<any>(true, []);

  loading = true;
  filteredDataSource: any;
  columns: any = [];


  afterLoadDataSource: any = [];
  permissionType = PermissionType;

  appliedFilters: any = [];
  defaultFilterPredicate;
  previousMap: any;
  previousMapValues: any = {};
  filterDictionary = new Map<string, string[]>();

  ngOnInit(): void {
    this.filteredDataSource = new MatTableDataSource<any>();

    this.filteredDataSource.filterPredicate = (record, filter) => {
        let isMatch = true;

        for (const [key, value] of filter) {
            if (!value.length) continue;

            if (key === 'globalSearch') {
                for (const [, v] of Object.entries(record)) {
                    isMatch = value.split(',').some(item => `${v}`.trim().toLowerCase().includes(item));
                    if (isMatch) break;
                }
            } else if (typeof (value) === 'string') {
                isMatch = value.split(',').some(item => (record[key as keyof AssetFilter])?.trim().toLowerCase().includes(item));
            } else if (key === 'locationUpdated') {
                if (!value[0] || !value[1]) continue;
                isMatch = ((record['locationUpdated'] >= value[0]) && (record['locationUpdated'] <= value[1]));
            } else {
                isMatch = (value.includes(record[key as keyof AssetFilter]));
            }
            if (!isMatch) return false;
        }
        return isMatch;
    };

    this.defaultFilterPredicate = this.filteredDataSource.filterPredicate;

    if (this.checkBoxes && this.permission === PermissionType.Edit) this.columns.push('select');
    for (let i = 0; i < this.displayedColumns.length; i++) {
      this.columns.push(this.displayedColumns[i].key);
    }

    this.loadPresetFilters();
  }

  ngAfterViewInit(): void {
    if (this.paginate) this.filteredDataSource.paginator = this.paginator;
    this.filteredDataSource.sort = this.sort;
    this.filteredDataSource.data = this.dataSource;
  }

  ngAfterContentInit(): void {
    this.afterLoadDataSource = this.filteredDataSource;
    this.loading = false;
  }

  applySearch(event: Event) {
    const filterValue = ((event.target as HTMLInputElement).value).trim().replace(/\s*,\s*/g, ',').toLowerCase();
    const filterName = (event.target as HTMLInputElement).name;

    this.updateFilteredDataSource(filterName, filterValue);
  }

  applyFilter(value: string, column: string) {
    const current = this.filterDictionary.get(column) ?? [];
    const index = current.indexOf(value);
    if (index === -1) {
        current.push(value);
    } else {
        current.splice(index, 1);
    }

    this.updateFilteredDataSource(column, current);
  }

  dateRangeChange(start: HTMLInputElement, end: HTMLInputElement) {
    const range = [];
    if (start.value && end.value) {
        const startDate = new Date(start.value).setHours(0, 0, 0, 0);
        const endDate = new Date(end.value).setHours(23, 59, 59, 999);

        range.push(startDate);
        range.push(endDate);
    }

    this.updateFilteredDataSource('locationUpdated', range);
  }

  updateFilteredDataSource(key: string, value: any) {
    this.filterDictionary.set(key, value);
    this.filteredDataSource.filter = this.filterDictionary;

    sessionStorage.setItem(`${this.uniqueKey}-map`, JSON.stringify(Object.fromEntries(this.filterDictionary)));

    if (this.filteredDataSource.paginator) {
      this.filteredDataSource.paginator.firstPage();
    }
  }

  loadPresetFilters() {
    this.previousMap = JSON.parse(sessionStorage.getItem(`${this.uniqueKey}-map`));
    if (this.previousMap) {
        Object.entries(this.previousMap).forEach(([key, value]) => {
            if (key === 'locationUpdated') {
                this.previousMapValues[key] = { start: new Date(value[0]), end: new Date(value[1]) };
            } else if (Array.isArray(value)) {
                this.previousMapValues[key] = value.map(x => x.replaceAll(' ', ''));
            } else {
                this.previousMapValues[key] = value;
            }

            this.updateFilteredDataSource(key, value);
        });
    }
  }

   /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.filteredDataSource.data.length;
    return numSelected === numRows && numRows > 0;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.filteredDataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }

  create() {
    this.createClick.emit();
  }

  import() {
    this.importClick.emit();
  }

  async export() {
    const data = this.filteredDataSource.filteredData;
    if (this.exportRowOverride) {
        data.forEach(row => this.exportRowOverride(row));
    }
    await Utils.downloadCsv(data);
  }

  deleteMany() {
    const dialogRef = this.dialog.open(this.deleteManyDialog);
    dialogRef.afterClosed().subscribe(result => {
            if (result === 'yes') {
                const ids = this.selection.selected.map(s => s.id);
                this.deleteClick.emit(ids);
            }
        });
  }

  delete(id: any) {
    const dialogRef = this.dialog.open(this.deleteDialog);
    dialogRef.afterClosed().subscribe(result => {
            if (result === 'yes') {
                this.deleteClick.emit(id);
            }
        });
  }

  click(id: string, route: boolean) {
    if (route) this.rowClick.emit(id);
  }

  handleActionClick(key): void {
    const ids = this.selection.selected.map(s => s.id);
    this.actionClick.emit({ key, ids });
  }
}
