/* eslint-disable complexity */
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';

import { HttpService } from 'app/services/http.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Utils } from 'app/utils';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
})
export class ActivityComponent implements OnInit {

  @ViewChild('eventDialog') eventDialog: any;

  @Output() optionChange = new EventEmitter<any>();

  @Input() id: number;
  @Input() table: string;
  @Input() showAddButton = true;
  @Input() tab = false;
  @Input() maxEventRows = 1000;
  @Input() valueFormatters: any;

  loading = true;

  utils = Utils;
  events = [];

  comment = '';
  posting = false;

  properties;
  event;
  allEventRows = [];

  displayedColumns = [
    { key: 'property', label: 'Property', type: 'string' },
    { key: 'value', label: 'Value', type: 'string' },
  ];


  constructor(private _httpService: HttpService, private dialog: MatDialog) { }

  async ngOnInit() {
    await this.init();
  }

  async init() {
    this.loading = true;
    await this.build();
    this.loading = false;
  }

  private getFormattedPropertyValue(propField: string, value: any) {
    if (this.valueFormatters) {
        const property = propField.replace(/\s/g, '').toLowerCase();
        const [fn] = Object.keys(this.valueFormatters).filter(f => f.toLowerCase() === property).map(f => this.valueFormatters[f]);
        if (fn) return fn(value);
    }
    return value;
  }

  private async build() {

    this.allEventRows = await this._httpService.get(`events/${this.id}?table=${this.table}`);

    if (this.allEventRows.length - this.maxEventRows > 0) {
      this.allEventRows = this.allEventRows.slice(this.allEventRows.length - this.maxEventRows, this.allEventRows.length);
    }

    for (const eventRow of this.allEventRows) {

      if (!this.events.some((e: any) => e.eventId === eventRow.eventId)) {

        const properties = this.allEventRows.filter((er: any) => er.eventId === eventRow.eventId);

        const event: any = {
          source: properties[0].source,
          event: properties[0].event,
          eventId: properties[0].eventId,
          date: properties[0].date,
          name: properties[0].name,
          properties: properties,
        };

        if (event.source === 'controlCenter') {

          event.event = event.event.split(' : ')[1];

          if (event.event === 'Modified') {

            const fields: any = [];

            for (let b = 0; b < properties.length; b++) {

              if (properties[b].property !== 'table' && properties[b].property !== 'id') {
                const [propField, valueType] = properties[b].property.split(' : ');
                const currentField = fields.find(f => f.field === propField);
                const value = this.getFormattedPropertyValue(propField, properties[b].value);

                if (!currentField) {
                  if (valueType === 'new') {
                    fields.push({ field: propField, new: value });
                  } else {
                    fields.push({ field: propField, old: value });
                  }
                } else {
                  if (valueType === 'new') {
                    currentField.new = value;
                  } else {
                    currentField.old = value;
                  }
                }
              }
            }

            let paragraph;

            for (let c = 0; c < fields.length; c++) {

              if (!fields[c].old) {
                paragraph = `<p>Changed <b>${fields[c].field}</b> to <b>${fields[c].new}</b></p>`;
                if (event.notes) {
                    event.notes += paragraph;
                } else {
                    event.notes = paragraph;
                }
              } else if (!fields[c].new) {
                paragraph = `<p>Removed <b>${fields[c].old}</b> from <b>${fields[c].field}</b></p>`;
                if (event.notes) {
                    event.notes += paragraph;
                } else {
                    event.notes = paragraph;
                }
              } else {
                paragraph = `<p>Changed <b>${fields[c].field}</b> from <b>${fields[c].old}</b> to <b>${fields[c].new}</b></p>`;
                if (event.notes) {
                    event.notes += paragraph;
                } else {
                    event.notes = paragraph;
                }
              }
            }
          } else if (event.event === 'Comment') {
            const property = properties.find(p => p.property === 'comment');

            event.notes = '<p>' + property.value + '</p>';
            event.notes = event.notes.replace(/\r\n\r\n/g, '</p><p>').replace(/\n\n/g, '</p><p>');
            event.notes = event.notes.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
          } else if (event.event === 'Demo Event') {
            const property = properties.find(p => p.property === 'demoEvent');
            event.notes = property.value;
          } else if (event.event === 'Feature Flag Event') {
            const property = properties.find(p => p.property === 'featureFlagEvent');
            event.notes = '<p>' + property.value + '</p>';
            event.notes = event.notes.replace(/\r\n/g, '<br />').replace(/\n/g, '<br />');
          }
        }

        this.events.push(event);
      }
    }

    setTimeout(() => this.scrollToBottom()); // let the activity render before scrolling to bottom

  }

  async addComment() {
    this.posting = true;
    await this._httpService.post('events/comments', { table: this.table, comment: this.comment, tableId: this.id });
    this.comment = '';
    await this.build();
    this.scrollToBottom();
    this.posting = false;
  }

  @ViewChild('activity') private myScrollContainer: ElementRef;

  scrollToBottom(): void {
    try {
      this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight;
    } catch (err) { console.error('failed to scrollToBottom', err); }
  }

  openEventDialog(event) {
    this.event = event;
    const dialog = this.dialog.open(this.eventDialog);
    dialog.afterClosed().subscribe(() => {
      this.event = null;
    });
  }
}
