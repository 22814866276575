<div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom">
    <h1 class="h2">Features</h1>
</div>


<div *ngIf="!loading" class="row scrollContainer col-md-10" style="margin-bottom: 20px;">
    <div class="col-md-9 mb-9 scrollColumn tab">
        <app-table [dataSource]="dataSource" [displayedColumns]="displayedColumns" *ngIf="!loading"
            uniquekey="features" (rowClick)="onRowClick($event)"></app-table>
    </div>
    <div class="col-md-3">
        <app-activity [tab]="true" [id]="0" table="feature" />
    </div>
</div>

<ng-template #updateDialog>
    <h2 matDialogTitle>{{selectedFeature.name}}</h2>
    <p>{{selectedFeature.description}}</p>
    <div>
        <mat-checkbox color="primary" name="enabled" [(ngModel)]="enabledCheckboxValue">
            Enable By Default
        </mat-checkbox>
    </div>
    <hr>
    <div>
        <button mat-raised-button class="enable-button" color="primary" (click)="save()" >
            Save
        </button>
    </div>

</ng-template>