<ng-container *ngIf="vm$ | async as vm; else empty">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 mb-3">
        <h1 class="h2">weavix™ Instance: <span *ngIf="vm.instance">{{vm.instance.name}}</span></h1>
    </div>

    <div *ngIf="loading">loading</div>
    <div *ngIf="errorMessage" class="alert alert-danger hovering-alert">
        <strong>Error occurred.</strong>
        {{errorMessage}}
    </div>

    <mat-tab-group animationDuration="0ms" [(selectedIndex)]="selectedIndex">
        <mat-tab label="Information">
            <app-instance-information [instance]="vm.instance" [companyId]="companyId" [(loading)]="loading"
                (showErrorMessageChange)="handleError($event)" />
        </mat-tab>
        <mat-tab *ngIf="vm.permissions && vm.instance && vm.instance.id !== 0"
            [disabled]="!(vm.permissions['SupportAccess'] > permissionType.None)" label="Temporary Support Access">
            <app-instance-support-access *ngIf="vm.permissions['SupportAccess'] > permissionType.None"
                [instance]="vm.instance" [permission]="vm.permissions['SupportAccess']"
                (failedLoad)="handleError($event)" />
        </mat-tab>
        <mat-tab *ngIf="vm.permissions && vm.instance && vm.instance.id !== 0"
            [disabled]="!(vm.permissions['SupportAccess'] > permissionType.None)" label="Records">
            <app-instance-records *ngIf="vm.permissions['SupportAccess'] > permissionType.None" [instance]="vm.instance"
                [permission]="vm.permissions['SupportAccess']" (failedLoad)="handleError($event)" />
        </mat-tab>

        <mat-tab *ngIf="vm.permissions && vm.instance && vm.instance.id !== 0"
            [disabled]="!(vm.permissions['TwoWaltDemo'] > permissionType.Read)" label="2 Walt Demo">
            <app-instance-two-walt-demo *ngIf="vm.permissions['TwoWaltDemo'] > permissionType.Read"
                [instance]="vm.instance" [permission]="vm.permissions['TwoWaltDemo']"
                (failedLoad)="handleError($event)">
            </app-instance-two-walt-demo>
        </mat-tab>
        <mat-tab label="Feature Flags" *ngIf="vm.permissions && vm.instance && vm.instance.id !== 0"
            [disabled]="!(vm.permissions['FeatureFlags'] > permissionType.None)">
            <app-instance-feature-flags [instance]="vm.instance"></app-instance-feature-flags>
        </mat-tab>

    </mat-tab-group>
</ng-container>

<ng-template #empty>
    <div>loading</div>
</ng-template>